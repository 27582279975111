import { Layout as AntLayout } from 'antd'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useRouter } from '@manage/core/router'
import Header from './components/Header'
import Sider from './components/Sider'
import Breadcrumb from './components/Breadcrumb'
import routes, { menus as allMenus } from '@/pages'
import { useSelector } from '@/store'

const { Content } = AntLayout

const MainLayout = () => {
  const menuCodeList = useSelector(state => state.global.menuCodeList)
  const isRoot = useSelector(state => state.global.isRoot)
  const children = useRoutes(routes)
  const {
    location: { pathname },
  } = useRouter()
  const location = useLocation()
  useEffect(() => {
    // 每次路由改变时，收集新的页面视图事件
    window.collectEvent('predefinePageView', {
      url: window.location.origin + window.location.pathname,
      url_path: location.pathname,
    })
  }, [location.pathname])
  // 无当前路由权限，跳转到第一个有权限的页面
  const route = allMenus
  const menus = allMenus
  const firstPath = menus || []
  // if (firstPath) {
  //   debugger
  //   return <Navigate to={firstPath} />
  // }

  return (
    <AntLayout className={'h-full flex flex-row '} style={{ backgroundImage: 'url(https://cdn.ytmpw.com/yp-js-manage/images/yp_manage_start-graditionBackGround.png)', backgroundRepeat: 'no-repeat', backgroundSize: '100%,300px' }}>
      <Sider />
      <AntLayout style={{ backgroundColor: 'transparent' }}>
        <Header />
        <Content
          className={'flex-1 flex relative h-full flex-col overflow-auto'}
        >
          {/* <Breadcrumb /> */}
          {children}
        </Content>
      </AntLayout>
    </AntLayout>
  )
}

export default MainLayout
