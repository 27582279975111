export const { MODE } = import.meta.env
export const { DEV } = import.meta.env

export const API_PREFIX = {
  dev: 'http://stars-develop.starfilled.cn/',
  develop: 'http://stars-develop.starfilled.cn/',
  test: 'http://stars-test.starfilled.cn/',
  master: 'https://stars-master.starfilled.cn/',
  prod: 'https://stars-prod.starfilled.cn/',
}[MODE]

export const ADMIN_PREFIX = {
  dev: 'https://goss-dev.yupaowang.com/',
  develop: 'https://goss-dev.yupaowang.com/',
  test: 'https://goss-test.yupaowang.com/',
  master: 'https://goss-master.yupaowang.com/',
  prod: 'https://goss.yupaowang.com/',
}[MODE]

export const CDN_PREFIX = 'https://cdn.ytmpw.com/yupao-manage-module/star/'

export const PHP_MNG_PREFIX = {
  dev: 'https://backend-test.yupaowang.com/',
  develop: 'https://backend-test.yupaowang.com/',
  test: 'https://backend-test.yupaowang.com/',
  master: 'https://backend-master.yupaowang.com/',
  prod: 'https://backend.yupaowang.com/',
}[MODE]

export const BUSINESS = 'star'

// 系统名称
export const SYSTEM_NAME = '繁星系统'

/** 系统LOGO */
// export const SYSTEM_LOGO = `${CDN_PREFIX}assets/logo.png`
export const SYSTEM_LOGO = `https://cdn.ytmpw.com/yp-js-manage/images/yp_manage_startSystem-logo.png`

// 当前租户
export const TENANT = 'star'

// 系统分配的超管ID (uaa服务)
export const SUPER_ID = -6

// ! crm的租户ID (跟uaa不一样)
// 所属租户id,1鱼泡直聘，2scrm，3客服系统,4质检,6审核系统。默认值2
export const TENANT_ID = 6

/** 上传appId */
export const UPLOAD_APP_ID = 105

/** 上传entryId */
export const UPLOAD_ENTRY_ID = 555675

/** monorepo反向引用配置  后续会把租户等放过来 */
export const MONO_CONFIG = {
  /** 是否需要scrm 比如info */
  notNeedScrm: false,
}
